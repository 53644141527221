// model
import {
  contextMenuRecordOperation,
  recordOperation,
  recordState,
  recordType
} from "@/model/record/recordModel";
import {
  categoryType,
  isAllowedOperation
} from "@/model/category/categoryModel";

// store
import store from "@/store/index";

// services
import { isValidOperation } from "@/services/record/recordService";
import {
  isAllowedAgendaItemOperation,
  isValidAgendaItemOperation
} from "@/services/agenda/agendaRecordService";

// mixins
import { dragDropRecordMixin } from "@/mixins/shared/base/dragDrop/dragDropRecordMixin";
import { agendaItemOperation } from "@/model/agenda/item/agendaItemModel";

export const contextMenuActionMixin = {
  data() {
    return {
      draggedRecord: undefined,
      destinationRecord: undefined,
      move: "move"
    };
  },
  mixins: [dragDropRecordMixin],
  computed: {},
  methods: {
    /**
     * Verify if Action is Valid
     * @param {Delete: string, Cut: string, Copy: string, Paste: string, Select: string} action
     * @return {any|boolean}
     */
    isValidAction(action) {
      try {
        switch (action) {
          case contextMenuRecordOperation.Copy: {
            if (this.selectedRecord?.recordTypeId === recordType.ITEM) {
              return (
                isValidAgendaItemOperation(
                  this.selectedRecord,
                  agendaItemOperation.refer
                ) && this.isEnabledCommandCopy()
              );
            }
            return (
              isValidOperation(this.selectedRecord, recordOperation.Move) &&
              this.isEnabledCommandCopy()
            );
          }
          case contextMenuRecordOperation.Cut: {
            if (this.selectedRecord?.recordTypeId === recordType.ITEM) {
              return (
                isValidAgendaItemOperation(
                  this.selectedRecord,
                  agendaItemOperation.defer
                ) && this.isEnabledCommandCopy()
              );
            }
            return (
              isValidOperation(this.selectedRecord, recordOperation.Move) &&
              this.isEnabledCommandCut()
            );
          }

          case contextMenuRecordOperation.JumpOriginal: {
            return this.selectedRecord?.isLink ?? false;
          }

          case contextMenuRecordOperation.Delete: {
            return isValidOperation(
              this.selectedRecord,
              recordOperation.Delete
            );
          }

          case contextMenuRecordOperation.Paste: {
            return this.movedRecords?.records?.length ?? 0
              ? this.isValidBatchPaste()
              : this.movedRecord?.record?.isLink
              ? false
              : this.isEnabledCommandPaste();
          }

          case contextMenuRecordOperation.Shortcut: {
            if (this.selectedRecord?.recordTypeId !== recordType.FOLDER)
              return false;
            if (!this.canCreateRecordShortcut) return false;
            return this.isEnabledCommandPaste(true);
          }

          case contextMenuRecordOperation.Select: {
            return (
              (this.selectedRecord?.id ?? -1) !==
              this.$router?.currentRoute?.params?.id
            );
          }

          case contextMenuRecordOperation.SubmitItems: {
            return this.isEnabledCommandSubmitRecallItems(
              agendaItemOperation.submit
            );
          }

          case contextMenuRecordOperation.RecallItems: {
            return this.isEnabledCommandSubmitRecallItems(
              agendaItemOperation.recall
            );
          }

          default:
            return true;
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Verify if Action is Allowed
     * Todo Improve
     * @param {Delete: string, Cut: string, Copy: string, Paste: string, Select: string} action
     * @return {any|boolean}
     */
    isAllowedAction(action) {
      try {
        switch (action) {
          case contextMenuRecordOperation.Copy:
            if (this.selectedRecord?.recordTypeId === recordType.SECTION)
              return false;
            return isAllowedOperation(
              this.selectedRecord,
              recordOperation.Create
            );

          case contextMenuRecordOperation.Cut:
            if (this.selectedRecord?.recordTypeId === recordType.SECTION)
              return false;
            return (
              isAllowedOperation(this.selectedRecord, recordOperation.Create) &&
              isAllowedOperation(this.selectedRecord, recordOperation.Delete)
            );

          case contextMenuRecordOperation.Paste:
            return this.movedRecords?.records?.length ?? 0
              ? this.isAllowedBatchPaste()
              : isAllowedOperation(this.selectedRecord, recordOperation.Create);

          case contextMenuRecordOperation.Shortcut:
            return (
              isAllowedOperation(
                this.selectedRecord,
                recordOperation.ShortcutCreate
              ) &&
              (this.movedRecord?.record?.ancestor?.id ?? -1) !==
                this.selectedRecord.id
            );

          case contextMenuRecordOperation.JumpOriginal: {
            return this.selectedRecord?.isLink ?? false;
          }

          case contextMenuRecordOperation.Delete: {
            return isAllowedOperation(
              this.selectedRecord,
              recordOperation.Delete
            );
          }

          case contextMenuRecordOperation.SubmitItems:
          case contextMenuRecordOperation.RecallItems: {
            return (
              ((this.selectedRecord?.recordTypeId ?? -1) ===
                recordType.MEETING ||
                (this.selectedRecord?.recordTypeId ?? -1) ===
                  recordType.SECTION) &&
              !(this.selectedRecord?.isDeleted ?? true)
            );
          }

          default:
            return true;
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Get Context Action Name (copy/cut needs to be refer/defer in case of Agenda Item)
     * @param title
     * @return {string|*}
     */
    getActionName(title) {
      switch (title) {
        case contextMenuRecordOperation.Copy: {
          if (this.selectedRecord?.recordTypeId === recordType.FOLDER)
            return `${title} as Shortcut`;
          if (this.selectedRecord?.isLink ?? false) return `${title} Shortcut`;
          return this.selectedRecord?.recordTypeId === recordType.ITEM
            ? "Refer"
            : title;
        }

        case contextMenuRecordOperation.Cut: {
          if (this.selectedRecord?.isLink ?? false) return `${title} Shortcut`;
          return this.selectedRecord?.recordTypeId === recordType.ITEM
            ? "Defer"
            : title;
        }

        case contextMenuRecordOperation.Paste: {
          return this.selectedRecord?.recordTypeId === recordType.ITEM ||
            this.selectedRecord?.recordTypeId === recordType.SECTION
            ? this.movedRecords.records.length
              ? `Commit ${
                  (this.movedRecords.moveType ?? "") ===
                  contextMenuRecordOperation.Copy
                    ? "Refer"
                    : "Defer"
                }`
              : `Commit ${
                  (this.movedRecord?.moveType ?? "") ===
                  contextMenuRecordOperation.Copy
                    ? "Refer"
                    : "Defer"
                }`
            : title;
        }

        case contextMenuRecordOperation.Delete: {
          return this.selectedRecord?.isLink ?? false
            ? `${title} Shortcut`
            : title;
        }

        default:
          return title;
      }
    },

    /**
     * Verify if Command Copy is enabled
     * @return {boolean}
     */
    isEnabledCommandCopy() {
      return (
        this.selectedRecord?.stateId !== recordState.checkedOut &&
        !this.selectedRecord.isLocked &&
        !this.selectedRecord.isReadOnly
      );
    },

    /**
     * Verify if Command Cut is enabled
     * @return {boolean}
     */
    isEnabledCommandCut() {
      return (
        isValidOperation(this.selectedRecord, recordOperation.Delete) &&
        this.selectedRecord?.stateId !== recordState.checkedOut &&
        !this.selectedRecord.isLocked &&
        !this.selectedRecord.isReadOnly
      );
    },

    /**
     *  Verify if Command Paste is enabled
     *  @param {{Boolean}} isPasteShortcut
     * @return {boolean}
     */
    isEnabledCommandPaste(isPasteShortcut = false) {
      const sourceRecord = this.movedRecord.record;

      if (!sourceRecord) {
        return false;
      }

      if (this.movedRecord.moveType === contextMenuRecordOperation.Cut)
        return (
          isValidOperation(this.selectedRecord, recordOperation.Move) &&
          this.isAllowedDrop(sourceRecord, this.selectedRecord)
        );
      else
        return (
          isValidOperation(this.selectedRecord, recordOperation.Move) &&
          this.isAllowedCopy(sourceRecord, this.selectedRecord, isPasteShortcut)
        );
    },

    /**
     * Verify if Command Paste is allowed - Batch
     * @return {boolean}
     */
    isAllowedBatchPaste() {
      for (const record of this.movedRecords.records) {
        if (!isAllowedOperation(record, recordOperation.Create)) return false;
      }
      return true;
    },

    /**
     * Verify if Command Paste is valid - Batch
     * @return {boolean}
     */
    isValidBatchPaste() {
      if (!this.movedRecords || this.movedRecords.records.length === 0) {
        return false;
      }

      return this.movedRecords.records.every(movedRecord => {
        const sourceRecord = movedRecord;

        if (!sourceRecord) {
          return false;
        }

        if (movedRecord.moveType === contextMenuRecordOperation.Cut) {
          return (
            isValidOperation(this.selectedRecord, recordOperation.Move) &&
            this.isAllowedDrop(sourceRecord, this.selectedRecord)
          );
        } else {
          return (
            isValidOperation(this.selectedRecord, recordOperation.Move) &&
            this.isAllowedCopy(sourceRecord, this.selectedRecord)
          );
        }
      });
    },

    /**
     * Validate if enabled Command Submit Recall Items
     * @param operation
     * @return {boolean}
     */
    isEnabledCommandSubmitRecallItems(operation) {
      if (this.selectedRecord?.isLocked) return false;

      if (!this.selectedRecord?.canUpdate) return false;

      const categories = store.getters["user/categories"] ?? undefined;
      return categories
        .filter(el => el.categoryType === categoryType.item)
        .some(c => !isAllowedAgendaItemOperation(operation, c.Id));
    }
  }
};
