<template>
  <v-menu v-model="show" :position-x="left" :position-y="top" absolute offset-y>
    <v-list dense>
      <template v-for="(item, index) in items">
        <v-list-item
          :key="index"
          @click="performContextMenuAction(item)"
          v-show="isAllowedAction(item.name)"
          :disabled="!isValidAction(item.name)"
        >
          <v-list-item-icon>
            <v-icon
              :color="getIconColorClass(item.name)"
              :disabled="!isValidAction(item.name)"
            >
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ getActionName(item.name) }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import {
  colorRecordDeletedClass,
  contextMenuRecordOperation,
  contextMenuRecordOperations,
  recordType
} from "@/model/record/recordModel";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";

import { eventNames } from "@/model/common/events/eventConst";
import { contextMenuActionMixin } from "@/mixins/shared/base/contextMenu/contextMenuActionMixin";

export default {
  name: "ContextMenu",
  mixins: [menuItemSettingsMixin, contextMenuActionMixin],
  props: {
    /**
     * records
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}[]}
     */
    records: undefined,
    selectedRecord: undefined,
    movedRecord: undefined,
    movedRecords: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      left: 0, // left position
      top: 0, // top position
      show: false, // affect display of context menu
      items: contextMenuRecordOperations,
      canCreateRecordShortcut: false
    };
  },
  computed: {},
  methods: {
    /**
     * closes context menu
     */
    close() {
      this.show = false;
      this.left = 0;
      this.top = 0;
    },

    /**
     * Opens Context Menu
     * @param event
     */
    open(event) {
      // updates position of context menu
      this.show = true;
      this.left = event.clientX;
      this.top = event.clientY;
    },

    /**
     * Opens Context Menu using Touch
     * @param event
     */
    openTouch(event) {
      // updates position of context menu using Touch
      this.show = true;
      this.left = event.touches[0].clientX;
      this.top = event.touches[0].clientY;
    },

    /**
     * Get Action Color Class
     * @param {{String}}name Context Action Name
     * @return {string|*}
     */
    getIconColorClass(name) {
      return name === contextMenuRecordOperation.Delete
        ? colorRecordDeletedClass
        : this.menuItemColorClass;
    },

    /**
     * Performs Context Menu Action
     * @param {{name: string, icon: string}} action
     */
    performContextMenuAction(action) {
      this.$emit(
        eventNames.onContextMenuAction,
        action?.name ?? contextMenuRecordOperation.Select
      );
    }
  },
  watch: {
    //watch right clicked Record Changed
    async selectedRecord() {
      try {
        if (!this.movedRecord.record) return false; //Verify if Copy/Cut Record Present
        if (this.selectedRecord?.recordTypeId !== recordType.FOLDER)
          return false; //Verify if right clicked Record is Folder

        //Determine if Copy/Cut record shortcut is already present in selected Record
        //Determine if Original Record is already present in selected Record
        // Determine if shortcut is Creatable
        // Will be used to disable Paste Shortcut Command if true

        this.canCreateRecordShortcut = await this.isShortcutCreatable(
          this.movedRecord.record,
          this.selectedRecord
        );
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
<style></style>
